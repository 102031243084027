<template>
  <v-alert
    class="mb-0"
    width="100%"
    :type="messageType"
    v-if="hasMessage"
  >
    <span v-html="message"></span>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'message',
      'messageType'
    ]),

    // メッセージがあるかないかを表す値
    hasMessage() {
      return this.messageType != ''
    }
  },
}
</script>